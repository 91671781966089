import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { email, TAnyEmailCodec } from "../../../shared/src/codecs/types/email";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { password, TAnyPasswordCodec } from "../../../shared/src/codecs/types/password";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";

export const ChangeStaffUserPasswordForm: TFormCodec<
    TRequiredCodec<{
        email: TAnyEmailCodec,
        password: TAnyPasswordCodec,
        company: TUnionCodec<[
            TLiteralCodec<"sail_homes">,
            TLiteralCodec<"sail_legal">,
            TLiteralCodec<"sail_probate">,
        ]>,
    }>,
    TRequiredCodec<{}>
> = 
    form(
        required({
            email: email(),
            password: password(),
            company: union([
                literal("sail_homes"),
                literal("sail_legal"),
                literal("sail_probate"),
            ]),
        }),
        required({}),
    );

export type TChangeStaffUserPasswordFormCodec = typeof ChangeStaffUserPasswordForm;
export type TChangeStaffUserPasswordForm = TTypeOfCodec<TChangeStaffUserPasswordFormCodec>;