import React from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { FrontQuestionFormCard } from "../../components/Front/Complex/FrontQuestionFormCard/FrontQuestionFormCard";
import FrontParagraphSmall from "../../components/Front/Simple/FrontParagraphSmall/FrontParagraphSmall";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { FrontSpacing } from "../../models/FrontSpacing";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { MaxWidth } from "../../components/BuildingBlocks/MaxWidth";
import { FrontFormSectionTitle } from "../../components/Front/Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontFormPrimaryButton } from "../../components/Front/Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { InlineFlex } from "../../components/BuildingBlocks/InlineFlex";
import { TOnboardingTabStatus } from "../../components/Front/Simple/FrontOnboardingTab/FrontOnboardingTab";
import { FrontInfoBubble } from "../../components/Front/Simple/FrontInfoBubble/FrontInfoBubble";
import { FrontColors } from "../../models/FrontColors";
import { ClientCasePropertyInformationForms } from "../../../../domain/codecs/form/ClientCaseForm";
import { WeightMedium } from "../../components/WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../components/WeightSemiBold/WeightSemiBold";
import { FrontLink } from "../../components/FrontLink/FrontLink";


const FrontPropertyInformationFormsContainer = (props: TContainerStateProps): JSX.Element => {
    const propertyInformationForms = props.state.forms.client_case_page.children.property_information_forms || ClientCasePropertyInformationForms.newDefault();

    const isStepComplete = propertyInformationForms.is_step_complete;

    const submit = () => props.dispatch({
        type: "CLIENT_SUBMIT_PROPERTY_INFORMATION_FORMS_AS_COMPLETE",
        payload: null,
    });

    return (
        <FrontQuestionFormCard
            title="Complete the Property Information Forms"
        >
            {/* ACTIVE */}
            {!isStepComplete &&
                <SpacingColumn spacing={FrontSpacing.LARGE_1}>
                    <SpacingColumn spacing={FrontSpacing.SMALL_3}>
                        {/* EXPLANATION */}
                        <FrontParagraphSmall>
                            <WeightSemiBold>
                                Property Information Forms are forms that are completed by the seller and given to the buyer's solicitor which give detailed
                                information about the property being sold.
                            </WeightSemiBold>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            Even if you haven't got a buyer for your property yet you should still complete these forms now so that we can prepare for any surprises.
                        </FrontParagraphSmall>

                        {/* WE CAN HELP */}
                        <FrontParagraphSmall>
                            The questions in these forms are the same as what other conveyancers use and <WeightSemiBold>the questions may, at times, be difficult to answer.</WeightSemiBold>
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            It's important that you answer all questions honestly as the buyer can hold you responsible.
                        </FrontParagraphSmall>

                        <FrontParagraphSmall>
                            <WeightBold>
                                Remember: We are here to help you if you need us!
                            </WeightBold>
                        </FrontParagraphSmall>
                    </SpacingColumn>

                    {/* QUESTIONS */}
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                        <FrontFormSectionTitle>
                            Please complete the following:
                        </FrontFormSectionTitle>

                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            {/* TA6 */}
                            <Form
                                formName="TA6 Property Information"
                                linkUrl="https://form.jotform.com/SailLegal/ta6"
                                guidance="This form contains detailed questions about the property you are selling."
                            />

                            {/* TA10 */}
                            <Form
                                formName="TA10 Inventory (Fittings & Contents)"
                                linkUrl="https://form.jotform.com/SailLegal/ta10"
                                guidance="This form contains questions on what is included (and what isn't) in the sale."
                            />

                            {/* TA7 */}
                            <Form
                                formName="TA7 Leasehold Information"
                                linkUrl="https://form.jotform.com/SailLegal/ta7"
                                guidance="This form contains questions around the Leasehold aspect of your property."
                            />
                        </SpacingColumn>
                    </SpacingColumn>

                    {/* SUBMIT */}
                    <InlineFlex>
                        <FrontFormPrimaryButton
                            icon="done"
                            label="I've completed these forms"
                            onClick={submit}
                        />
                    </InlineFlex>
                </SpacingColumn>
            }

            {/* COMPLETE */}
            {isStepComplete &&
                <FrontParagraphSmall>
                    We'll review the Property Information Forms you submitted to us and will let you know if we require anything else.
                </FrontParagraphSmall>
            }
        </FrontQuestionFormCard>
    );
};

export const getPropertyInformationFormsStatus = (props: TContainerStateProps): TOnboardingTabStatus => {
    if (props.state.forms.client_case_page.children.property_information_forms?.is_step_complete) {
        return "completed";
    }
    
    return "active";
};

export default FrontPropertyInformationFormsContainer;

const Form = (props: {
    formName: string,
    linkUrl: string,
    guidance: string,
}): JSX.Element =>
    <SpacingColumn spacing={FrontSpacing.SMALL_2}>
        <FrontParagraphSmall>
            <WeightMedium>
                The <WeightSemiBold>{props.formName}</WeightSemiBold> form by <FrontLink href={props.linkUrl} target="_blank">clicking here.</FrontLink>
            </WeightMedium>
        </FrontParagraphSmall>

        {/* GUIDANCE */}
        <MaxWidth width="400px">
            <FrontInfoBubble color={FrontColors.PRIMARY_22}>
                {props.guidance}
            </FrontInfoBubble>
        </MaxWidth>
    </SpacingColumn>;
