import { actions as bookViewingActions } from "./actions/legacy/bookViewing";
import { actions as crmSearchActions } from "./actions/legacy/crmSearchFlow";
import { actions as getListingActions } from "./actions/legacy/getListing";
import { actions as crmListingPerformanceActions } from "./actions/legacy/crmListingPerformance";
import { actions as crmListingsActions } from "./actions/legacy/crmListings";
import { actions as crmPartyActions } from "./actions/legacy/crmParty";
import { actions as crmEnquiryActions } from "./actions/legacy/crmEnquiry";
import { actions as crmAddNewEnquiryActions } from "./actions/legacy/crmAddNewEnquiry";
import { actions as resendMagicLink } from "./actions/legacy/resendMagicLink";
import { actions as crmLegalCaseEnquiries } from "./actions/legacy/crmLegalCaseEnquiries";
import { actions as crmLegalCaseDocuments } from "./actions/legacy/crmLegalCaseDocuments";
import { actions as crmLegalCaseLegacyActions } from "./actions/legacy/crmLegalCase";
// NEW PATTERN
import { actions as buyerEnquiryActions } from "./actions/buyerEnquiryActions";
import { actions as crmLegalCaseDetailsActions } from "./actions/crmLegalCaseDetailsActions";
import { actions as crmLegalCasesTitleChecksActions } from "./actions/crmLegalCasesTitleChecksActions";
import { actions as jobsActions } from "./actions/jobsActions";
import { actions as newCrmListingActions } from "./actions/crmListingActions";
import { actions as newCrmListingEnquiry } from "./actions/crmListingEnquiry";
import { actions as newCrmListingPerformanceActions } from "./actions/crmListingPerformanceActions";
import { actions as crmAdminActions } from "./actions/crmAdminActions";
import { actions as crmGlobalLegalActions } from "./actions/crmGlobalLegalActions";
import { actions as buyer2FAActions } from "./actions/buyer2FAActions";
import { actions as clientCaseActions } from "./actions/clientCaseActions";
import { actions as crmLegalCaseLedgerActions } from "./actions/crmLegalCaseLedgerActions";
import { actions as crmTriageActions } from "./actions/crmTriageActions";
import { actions as crmInboxAssigneeActions } from "./actions/crmInboxAssigneeActions";
import { actions as crmLegalCaseInboxActions } from "./actions/crmLegalCaseInboxActions";
import { actions as crmIntroducersActions } from "./actions/crmIntroducersActions";
import { actions as crmMarketingCampaignActions } from "./actions/crmMarketingCampaignActions";
import { actions as crmLegalCaseSatisfactionActions } from "./actions/crmLegalCaseSatisfactionActions";
import { actions as crmClientCompanyPartnersActions } from "./actions/crmClientCompanyPartnersActions";
import { actions as crmProbateHelplineCallbackActions } from "./actions/crmProbateHelplineCallbackActions";
import { actions as crmQuoteRatesActions } from "./actions/crmQuoteRatesActions";
import { actions as frontPublicQuoteActions } from "./actions/frontPublicQuoteActions";
import { actions as crmCaseHandlerAutoAssignmentActions } from "./actions/crmCaseHandlerAutoAssignmentActions";
import { actions as caseRoadblockActions } from "./actions/crmLegalCaseRoadblocksActions";
import { actions as crmLegalCaseContractBlockActions } from "./actions/crmLegalCaseContractBlockActions";
import { actions as crmLegalCaseTitleChecksBlockActions } from "./actions/crmLegalCaseTitleChecksBlockActions";
import { actions as frontPublicPropertyReportActions } from "./actions/frontPublicPropertyReportActions";
import { actions as crmLegalCaseChargesAndRestrictionsBlockActions } from "./actions/crmLegalCaseChargesAndRestrictionsBlockActions";
import { actions as crmLegalCasesActions } from "./actions/crmLegalCasesActions";
import { actions as crmLegalCaseCloseChaseContinueActions } from "./actions/crmLegalCaseCloseChaseContinueActions";
import { actions as crmBearerTokenManagementActions } from "./actions/crmBearerTokenManagementActions";
import { actions as crmCreateStaffUserActions } from "./actions/crmCreateStaffUserActions";
import { actions as crmChangeStaffUserPasswordActions } from "./actions/crmChangeStaffUserPasswordActions";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { TActionsDefinitionsList } from "./actions/TAction";

export const actions: TActionsDefinitionsList = [
    {
        type: "VIEW_CRM_INDEX",
        run: (obs$: Observable<unknown>) => {
            obs$.pipe(
                tap(() => window.location.href = "/crm/search"),
            ).subscribe();
        }
    },
    ...jobsActions,
    ...newCrmListingActions,
    ...crmLegalCasesTitleChecksActions,
    ...crmLegalCaseDetailsActions,
    ...crmLegalCaseCloseChaseContinueActions,
    ...crmLegalCaseContractBlockActions,
    ...crmLegalCaseTitleChecksBlockActions,
    ...buyerEnquiryActions,
    ...newCrmListingPerformanceActions,
    ...crmAdminActions,
    ...crmGlobalLegalActions,
    ...buyer2FAActions,
    ...clientCaseActions,
    ...crmLegalCaseLedgerActions,
    ...crmTriageActions,
    ...crmInboxAssigneeActions,
    ...crmLegalCaseInboxActions,
    ...crmIntroducersActions,
    ...crmMarketingCampaignActions,
    ...crmLegalCaseSatisfactionActions,
    ...crmClientCompanyPartnersActions,
    ...crmProbateHelplineCallbackActions,
    ...crmQuoteRatesActions,
    ...frontPublicQuoteActions,
    ...frontPublicPropertyReportActions,
    ...crmCaseHandlerAutoAssignmentActions,
    ...caseRoadblockActions,
    ...crmLegalCaseChargesAndRestrictionsBlockActions,
    ...crmLegalCasesActions,
    ...crmBearerTokenManagementActions,
    ...crmCreateStaffUserActions,
    ...crmChangeStaffUserPasswordActions,
    
    // LEGACY
    ...bookViewingActions,
    ...crmSearchActions,
    ...getListingActions,
    ...crmListingPerformanceActions,
    ...crmListingsActions,
    ...crmPartyActions,
    ...crmEnquiryActions,
    ...crmAddNewEnquiryActions,
    ...resendMagicLink,
    ...crmLegalCaseEnquiries,
    ...crmLegalCaseDocuments,
    ...newCrmListingEnquiry,
    ...crmLegalCaseLegacyActions,
];