import React from "react";
import { CRMTabPage } from "../components/Complex/CRMTabPage/CRMTabPage";
import { CRMPageWrap } from "../components/Simple/CRMPageWrap/CRMPageWrap";
import { CRMAdminPageAssignJobsContainer, getUnsassignedJobsCount } from "../containers/CRMAdminPageAssignJobsContainer/CRMAdminPageAssignJobsContainer";
import { TContainerStateProps } from "../state/TContainerStateProps";
import { TAdminUserEditable } from "../../../domain/codecs/formEditable/AdminUserEditable";
import { CRMHomesMenuBarContainer } from "../containers/CRMHomesMenuBarContainer/CRMHomesMenuBarContainer";
import { CRMAdminPageKlyantContainer } from "../containers/CRMAdminPageKlyantContainer/CRMAdminPageKlyantContainer";
import { CRMAdminPageBearerTokenManagementContainer } from "../containers/CRMAdminPageBearerTokenManagementContainer/CRMAdminPageBearerTokenManagementContainer";
import { CRMLegalMenuBarContainer } from "../containers/CRMLegalMenuBarContainer/CRMLegalMenuBarContainer";
import { CRMProbateMenuBarContainer } from "../containers/CRMProbateMenuBarContainer/CRMProbateMenuBarContainer";
import { getCurrentBrandHostnameAndEnv } from "../../../domain/models/Enviroment";
import { CRMAdminCreateStaffUserContainer } from "../containers/CRMAdminCreateStaffUserContainer/CRMAdminCreateStaffUserContainer";
import { CRMAdminChangeStaffUserPasswordContainer } from "../containers/CRMAdminChangeStaffUserPasswordContainer/CRMAdminChangeStaffUserPasswordContainer";

export const CRMAdminPage = (props: TContainerStateProps): JSX.Element => {

    const getAdminUsers = (): Array<TAdminUserEditable> => 
        props.state.forms.homes_admin_page.data.output.adminUsers
    ;

    const hasKlyantError = props.state.forms.homes_admin_page.data.output.klyant.hasError;

    const currentBrand = getCurrentBrandHostnameAndEnv("web", window.location.hostname);

    return (
        <CRMPageWrap>
            {/* MENU BAR */}
            {currentBrand === "sailhomes" && <CRMHomesMenuBarContainer {...props} />}
            {currentBrand === "saillegal" && <CRMLegalMenuBarContainer {...props} />}
            {currentBrand === "sailprobate" && <CRMProbateMenuBarContainer {...props} />}

            {/* BODY */}
            <CRMTabPage
                tabs={[
                    {
                        label: "Assign User Jobs",
                        icon: getUnsassignedJobsCount(getAdminUsers()) ? "error-outline" : undefined,
                        iconColor: "highlights-instructional-brick-0",
                        content: <CRMAdminPageAssignJobsContainer
                            dispatch={props.dispatch}
                            state={props.state}
                        />,
                    },
                    {
                        label: "Klyant",
                        icon: hasKlyantError ? "error-outline" : undefined,
                        iconColor: "highlights-instructional-brick-0",
                        content: <CRMAdminPageKlyantContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />,
                    },
                    {
                        label: "Bearer Token Management",
                        content: <CRMAdminPageBearerTokenManagementContainer
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    },
                    {
                        label: "Create Staff User",
                        content: <CRMAdminCreateStaffUserContainer
                        state={props.state}
                        dispatch={props.dispatch}
                    />
                    },
                    {
                        label: "Change Staff User Password",
                        content: <CRMAdminChangeStaffUserPasswordContainer
                        state={props.state}
                        dispatch={props.dispatch}
                    />
                    }
                ]}
            />
        </CRMPageWrap>
    );
};
