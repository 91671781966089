import { Observable } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { formOperation } from "../../wrappers/formOperation";
import { TActionsDefinitionsList } from "./TAction";
import { action } from "./actionFunctions";
import { TChangeStaffUserPasswordForm } from "../../../../domain/codecs/form/ChangeStaffUserPasswordForm";

export const actions: TActionsDefinitionsList = [
    action("CHANGE_STAFF_USER_PASSWORD_FORM_CHANGE", (obs$: Observable<TChangeStaffUserPasswordForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap((payload) => setState(lens.change_staff_user_password_page.set(payload))),
        ).subscribe();
    }),
    action("CHANGE_STAFF_USER_PASSWORD_FORM_SUBMIT", (obs$: Observable<TChangeStaffUserPasswordForm>, lens, setState, getState, routes, dispatch) => {
        obs$.pipe(
            tap(() => setState(lens.change_staff_user_password_page.status.set("submitting"))),
            switchMap((form) => formOperation("ChangeStaffUserPassword", form)),
            tap((form) => setState(lens.change_staff_user_password_page.set(form))),
        ).subscribe();
    }),
];