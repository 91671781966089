import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { ListingEnquiryForm, TListingEnquiryFormCodec } from "./formEditable/ListingEnquiryForm";
import { HomesAdminPagePartial, THomesAdminPagePartialCodec } from "./statePartials/HomesAdminPagePartial";
import { JobsPartial, TJobsPartialCodec } from "./statePartials/JobsPartial";
import { PublicOffersPagePartial, TPublicOffersPagePartialCodec } from "./statePartials/PublicOffersPagePartial";
import { LegalCaseDetailsPagePartial, TLegalCaseDetailsPagePartialCodec } from "./statePartials/LegalCaseDetailsPagePartial";
import { ListingPagePartial, TListingPagePartialCodec } from "./statePartials/ListingPagePartial";
import { ListingPerformancePagePartial, TListingPerformancePagePartialCodec } from "./statePartials/ListingPerformancePagePartial";
import { GlobalLegalPartial, TGlobalLegalPartialCodec } from "./statePartials/GlobalLegalPartial";
import { required, TRequiredCodec } from "../../shared/src/codecs/types/required";
import { createNewDefaultLens, TCodecLens } from "../../shared/src/codecs/codecLens";
import { TitleChecksPageFormIO, TTitleChecksPageFormIOCodec } from "./formIO/TitleChecksPageFormIO";
import { LegalCasesKeyDatesFormIO, TLegalCasesKeyDatesFormIOCodec } from "./formIO/LegalCasesKeyDatesFormIO";
import { TTwoFactorAuthFormCodec, TwoFactorAuthForm } from "./form/TwoFactorAuthForm";
import { ClientCaseForm, TClientCaseFormCodec } from "./form/ClientCaseForm";
import { LegalCaseLedgerPagePartial, TLegalCaseLedgerPagePartialCodec } from "./statePartials/LegalCaseLedgerPagePartial";
import { TriageActiveTypeVisible, TriageAssigneeActiveTypeVisible, TriageForm, TTriageActiveTypeVisibleCodec, TTriageAssigneeActiveTypeVisibleCodec, TTriageFormCodec } from "./form/TriageForm";
import { TInboxCountFormCodec, InboxCountForm } from "./form/InboxCountForm";
import { IntroducersForm, TIntroducersFormCodec } from "./form/IntroducerForm";
import { CaseCustomerSatisfactionForm, TCaseCustomerSatisfactionFormCodec } from "./form/CaseCustomerSatisfactionForm";
import { ClientCompanyPartnershipPageForm, TClientCompanyPartnershipPageFormCodec } from "./form/ClientCompanyPartnershipForm";
import { TProbateHelplineCallbackTypeFormCodec, ProbateHelplineCallbackTypeForm, TProbateHelplineCallbackTypeVisibleCodec, ProbateHelplineCallbackTypeVisible } from "./form/ProbateHelplineCallbackForm";
import { MarketingCampaignsForm, TMarketingCampaignsFormCodec } from "./form/MarketingCampaignForm";
import { QuoteRateActiveTypeVisible, QuoteRatePageForm, TQuoteRatePageFormCodec, TQuoteRateActiveTypeVisibleCodec } from "./form/QuoteRateForm";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { nullCodec, TNullCodec } from "../../shared/src/codecs/types/nullCodec";
import { TUuidCodec, uuid } from "../../shared/src/codecs/types/uuid";
import { PublicQuotePage, TPublicQuotePageCodec } from "./form/PublicQuoteForm";
import { CaseAutoAssignmentPageForm, TCaseAutoAssignmentPageFormCodec } from "./form/CaseAutoAssignmentForm";
import { RoadblocksForm, RoadblocksForm2, TRoadblocksForm2Codec, TRoadblocksFormCodec } from "./form/RoadblockForm";
import { TAnyStringCodec, string } from "../../shared/src/codecs/types/string";
import { CaseContractSectionForm, TCaseContractSectionFormCodec } from "./form/CaseContractSectionForm";
import { CaseTitleChecksForm, TCaseTitleChecksFormCodec } from "./form/CaseTitleChecksForm";
import { PublicPropertyReportPage, TPublicPropertyReportPageCodec } from "./form/PublicPropertyReportForm";
import { CaseChargesAndRestrictionsForm, TCaseChargesAndRestrictionsFormCodec } from "./form/CaseChargesAndRestrictionForm";
import { SessionFeatureToggleForm, TSessionFeatureToggleFormCodec } from "./form/SessionFeatureToggleForm";
import { TBooleanCodec, boolean } from "../../shared/src/codecs/types/boolean";
import { CasesPage, TCasesPageCodec } from "./form/CasesForm";
import { CreateServiceBearerTokenForm, TCreateServiceBearerTokenFormCodec } from "./form/CreateServiceBearerTokenForm";
import { CaseChaseModeForm, TCaseChaseModeFormCodec } from "./form/CasesChaseTransitionForm";
import { CreateStaffUserForm, TCreateStaffUserFormCodec } from "./form/CreateStaffUserForm";
import { ChangeStaffUserPasswordForm, TChangeStaffUserPasswordFormCodec } from "./form/ChangeStaffUserPasswordForm";

export type TStateCodec = TRequiredCodec<{
    block_column_picker: TRequiredCodec<{
        opens: TUnionCodec<[TNullCodec, TAnyStringCodec]>;
    }>,
    homes_admin_page: THomesAdminPagePartialCodec,
    jobs: TJobsPartialCodec,
    overlay: TRequiredCodec<{
        roadblocks: TRoadblocksFormCodec
    }>,
    public_offers_page: TPublicOffersPagePartialCodec,
    global_legal: TGlobalLegalPartialCodec,
    title_checks_page: TTitleChecksPageFormIOCodec,
    legal_cases_key_dates: TLegalCasesKeyDatesFormIOCodec,
    case_details_page: TLegalCaseDetailsPagePartialCodec,
    case_ledger_page: TLegalCaseLedgerPagePartialCodec,
    case_satisfaction_page: TCaseCustomerSatisfactionFormCodec,
    listing_page: TListingPagePartialCodec,
    listing_performance_page: TListingPerformancePagePartialCodec,
    listing_enquiry_page: TListingEnquiryFormCodec,
    buyer2FAPage: TTwoFactorAuthFormCodec,
    client_case_page: TClientCaseFormCodec,
    triage_page: TRequiredCodec<{
        expired: TTriageFormCodec,
        unassigned: TTriageFormCodec,
        active_type_visible: TTriageActiveTypeVisibleCodec,
    }>,
    case_inbox_page: TRequiredCodec<{
        unresolved: TTriageFormCodec,
    }>, 
    inbox_assignee_page: TRequiredCodec<{
        resolved: TTriageFormCodec,
        unresolved: TTriageFormCodec,
        confidential: TTriageFormCodec,
        untriaged: TTriageFormCodec,
        active_type_visible: TTriageAssigneeActiveTypeVisibleCodec,
    }>,
    inbox_count_form: TInboxCountFormCodec,
    introducers_block: TRequiredCodec<{
        introducer_id_open: TUnionCodec<[TNullCodec, TUuidCodec]>,
        data: TIntroducersFormCodec,
    }>,
    marketing_campaigns_block: TMarketingCampaignsFormCodec,
    client_company_partners_page: TClientCompanyPartnershipPageFormCodec,
    probate_helpline_callbacks_page: TRequiredCodec<{
        now: TProbateHelplineCallbackTypeFormCodec,
        completed: TProbateHelplineCallbackTypeFormCodec,
        active_type_visible: TProbateHelplineCallbackTypeVisibleCodec,
    }>,
    quote_rates_page: TRequiredCodec<{
        data: TQuoteRatePageFormCodec,
        active_type_visible: TQuoteRateActiveTypeVisibleCodec,
    }>,
    public_quote_page: TPublicQuotePageCodec,
    case_assignment_page_form: TCaseAutoAssignmentPageFormCodec,
    case_roadblocks_block: TRoadblocksForm2Codec,
    contracts_block: TCaseContractSectionFormCodec,
    case_title_checks_block: TCaseTitleChecksFormCodec,
    public_property_report_page: TPublicPropertyReportPageCodec,
    case_charges_and_restrictions_block: TCaseChargesAndRestrictionsFormCodec,
    session_feature_toggle_form: TSessionFeatureToggleFormCodec,
    weekly_kpi_is_showing: TBooleanCodec,
    cases_page: TCasesPageCodec,
    bearer_token_page: TCreateServiceBearerTokenFormCodec,
    chase_mode_form: TCaseChaseModeFormCodec,
    create_staff_user_page: TCreateStaffUserFormCodec,
    change_staff_user_password_page: TChangeStaffUserPasswordFormCodec,
}>;

export const state: TStateCodec = required({
    block_column_picker: required({
        opens: union([nullCodec(), string()]),
    }),
    homes_admin_page: HomesAdminPagePartial,
    jobs: JobsPartial,
    overlay: required({
        roadblocks: RoadblocksForm
    }),
    public_offers_page: PublicOffersPagePartial,
    global_legal: GlobalLegalPartial,
    title_checks_page: TitleChecksPageFormIO,
    legal_cases_key_dates: LegalCasesKeyDatesFormIO,
    case_details_page: LegalCaseDetailsPagePartial,
    case_ledger_page: LegalCaseLedgerPagePartial,
    case_satisfaction_page: CaseCustomerSatisfactionForm,
    listing_page: ListingPagePartial,
    listing_performance_page: ListingPerformancePagePartial,
    listing_enquiry_page: ListingEnquiryForm,
    buyer2FAPage: TwoFactorAuthForm,
    client_case_page: ClientCaseForm,
    triage_page: required({
        expired: TriageForm,
        unassigned: TriageForm,
        active_type_visible: TriageActiveTypeVisible,
    }),
    case_inbox_page: required({
        unresolved: TriageForm,
    }),
    inbox_assignee_page: required({
        resolved: TriageForm,
        unresolved: TriageForm,
        confidential: TriageForm,
        untriaged: TriageForm,
        active_type_visible: TriageAssigneeActiveTypeVisible,
    }),
    inbox_count_form: InboxCountForm,
    introducers_block: required({
        introducer_id_open: union([nullCodec(), uuid()]),
        data: IntroducersForm,
    }),
    marketing_campaigns_block: MarketingCampaignsForm,
    client_company_partners_page: ClientCompanyPartnershipPageForm,
    probate_helpline_callbacks_page: required({
        now: ProbateHelplineCallbackTypeForm,
        completed: ProbateHelplineCallbackTypeForm,
        active_type_visible: ProbateHelplineCallbackTypeVisible,
    }),
    quote_rates_page: required({
        data: QuoteRatePageForm,
        active_type_visible: QuoteRateActiveTypeVisible
    }),
    public_quote_page: PublicQuotePage,
    case_assignment_page_form: CaseAutoAssignmentPageForm,
    case_roadblocks_block: RoadblocksForm2,
    contracts_block: CaseContractSectionForm,
    case_title_checks_block: CaseTitleChecksForm,
    public_property_report_page: PublicPropertyReportPage,
    case_charges_and_restrictions_block: CaseChargesAndRestrictionsForm,
    session_feature_toggle_form: SessionFeatureToggleForm,
    weekly_kpi_is_showing: boolean(),
    cases_page: CasesPage,
    bearer_token_page: CreateServiceBearerTokenForm,
    chase_mode_form: CaseChaseModeForm,
    create_staff_user_page: CreateStaffUserForm,
    change_staff_user_password_page: ChangeStaffUserPasswordForm,
});

export type TState = TTypeOfCodec<TStateCodec>;

export type TStateLens = TCodecLens<TStateCodec, TStateCodec>;

export const stateLens: TStateLens = createNewDefaultLens(state);